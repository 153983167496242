import "bootstrap";

import jquery from "jquery";
export default (window.$ = window.jQuery = jquery);

// import external html files
$(function(){
  $("#includeDatenschutz").load("datenschutz.html");
});
$(function(){
  $("#includeImpressum").load("impressum.html");
});

// set scroll offset for header
var scrollOffset = 50;
var divId;
$('.nav-link').click(function(){
divId = $(this).attr('href');
 $('html, body').animate({
  scrollTop: $(divId).offset().top - scrollOffset
}, 100);
});

// make all carousel slides have same size
$(window).on('load resize orientationchange', ()=> {
    $('.carousel').each(function(){
      var items = $('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null,
          items.map(function(){
              return $(this).outerHeight()}).get() );
      items.css('min-height', maxHeight + 'px');
    });
});